const textArea = {
  'ui:widget': 'textarea',
  'ui:options': {
    emptyValue: '',
  },
};

const hiddenReadOnly = {
  'ui:readonly': true,
  'ui:widget': 'hidden',
};

const readonlyLocaleDate = {
  'ui:readonly': true,
  'ui:widget': 'LocaleDateTime',
  'ui:options': { dateFormat: 'yyyy-MM-dd HH:mm:ss' },
};

const immobilien = {
  id: hiddenReadOnly,
  didok: hiddenReadOnly,
  geom: hiddenReadOnly,
  geom_overwrite: {
    'ui:field': 'coordinatesPicker',
  },
  stationsbezeichnung: hiddenReadOnly,
  bahnhofkategorie: {
    'ui:hackEmptyValue': '',
    'ui:options': {
      'ui:emptyValue': '',
    },
  },
  has_changes: hiddenReadOnly,
  created_at: hiddenReadOnly,
  has_aktuell: hiddenReadOnly,
  leistungstypen_el: {
    'ui:field': 'serviceTypes',
    'ui:title': 'Leistungstypen (EL)',
  },
  leistungstypen_fl: {
    'ui:field': 'serviceTypes',
    'ui:title': 'Leistungstypen (FL)',
  },
  portfolio: {
    'ui:hackEmptyValue': '',
  },
  modified_at: readonlyLocaleDate,
  'ui:order': [
    'id',
    'didok',
    'stationsbezeichnung',
    'geom',
    'leistungstypen_el',
    'leistungstypen_fl',
    'created_at',
    'has_changes',
    'bezeichnung',
    'geom_overwrite',
    'team',
    'team_reinigung',
    'region',
    'portfolio',
    'bahnhofkategorie',
    'modified_at',
    'modified_by',
  ],
};

const zweitausbildungPois = {
  id: hiddenReadOnly,
  geom: hiddenReadOnly,
  foto: {
    'ui:widget': 'ImageWidget',
  },
  modified_at: readonlyLocaleDate,
  'ui:order': [
    'id',
    'geom',
    'name',
    'rail_away',
    'foto',
    'modified_at',
    'modified_by',
  ],
};

const zweitausbildungStations = {
  id: hiddenReadOnly,
  didok: hiddenReadOnly,
  geom: hiddenReadOnly,
  stationsbezeichnung: hiddenReadOnly,
  created_at: hiddenReadOnly,
  has_changes: hiddenReadOnly,
  selection: {
    // ui:emptyValue doesn't work see https://github.com/rjsf-team/react-jsonschema-form/issues/1041
    // so we use this hackish property to replace the value to empty string when the value is undefined.
    'ui:hackEmptyValue': '',
  },
  station: hiddenReadOnly,
  modified_at: readonlyLocaleDate,
  'ui:order': [
    'id',
    'didok',
    'geom',
    'stationsbezeichnung',
    'created_at',
    'has_changes',
    'is_border_station',
    'selection',
    'modified_at',
    'modified_by',
  ],
};

const bauprojekte = {
  id: hiddenReadOnly,
  didok: hiddenReadOnly,
  geom: hiddenReadOnly,
  has_changes: hiddenReadOnly,
  created_at: hiddenReadOnly,
  has_aktuell: hiddenReadOnly,
  modified_at: readonlyLocaleDate,
};

const mietvelo = {
  id: hiddenReadOnly,
  geom: hiddenReadOnly,
  general: {
    url_alias_identifier: {
      'ui:field': 'velovermietung',
    },
  },
  modified_at: readonlyLocaleDate,
  description: {
    text_de: textArea,
    text_fr: textArea,
    text_it: textArea,
    text_en: textArea,
    text_return_de: textArea,
    text_return_fr: textArea,
    text_return_it: textArea,
    text_return_en: textArea,
  },
};

const anlagenverantwortliche = {
  id: hiddenReadOnly,
  line_segment: { 'ui:field': 'SegmentationField' },
  geom: hiddenReadOnly,
  fahrbahn: { 'ui:field': 'PersonField', 'ui:hackEmptyValue': null },
  fahrstrom: { 'ui:field': 'PersonField', 'ui:hackEmptyValue': null },
  ingenieurbau: { 'ui:field': 'PersonField', 'ui:hackEmptyValue': null },
  natur: { 'ui:field': 'PersonField', 'ui:hackEmptyValue': null },
  bzu: { 'ui:field': 'PersonField', 'ui:hackEmptyValue': null },
  bnb: { 'ui:field': 'PersonField', 'ui:hackEmptyValue': null },
  wasser: { 'ui:field': 'PersonField', 'ui:hackEmptyValue': null },
  abwasser: { 'ui:field': 'PersonField', 'ui:hackEmptyValue': null },
  person: { 'ui:field': 'PersonField', 'ui:hackEmptyValue': null },
  leiter: { 'ui:field': 'PersonField', 'ui:hackEmptyValue': null },
  bahntechnik: { 'ui:field': 'PersonField', 'ui:hackEmptyValue': null },
  rollen: {
    items: {
      person: { 'ui:field': 'PersonField', 'ui:hackEmptyValue': null },
    },
  },
  modified_at: readonlyLocaleDate,
  'ui:order': [
    'id',
    'line_segment',
    'geom',
    'av',
    'bp_von',
    'bp_bis',
    'region',
    'nl',
    'fahrbahn',
    'fahrstrom',
    'ingenieurbau',
    'natur',
    'bzu',
    'bnb',
    'wasser',
    'abwasser',
    'bahntechnik',
    'modified_at',
    'modified_by',
  ],
};

const netzentwicklung = {
  id: hiddenReadOnly,
  line_segment: {
    'ui:field': 'SegmentationField',
    'ui:title': 'Zuletzt lalal',
  },
  geom: hiddenReadOnly,
  rollen: {
    'ui:options': {
      'ui:addButtonLabel': 'Person hinzufügen',
      'ui:toggleOptions': {
        'ui:label': 'Rollen überschreiben',
        'ui:condition': (selectedFeats) => selectedFeats?.length > 1,
        'ui:message:disabled':
          'Achtung: Checkbox nur anwählen, wenn alle Rollen der ausgewählten Linienabschnitte überschrieben werden sollen.',
        'ui:message:enabled':
          'Achtung: Checkbox nur anwählen, wenn alle Rollen der ausgewählten Linienabschnitte überschrieben werden sollen.',
      },
    },
    items: {
      'ui:title': '',
      person: {
        'ui:field': 'PersonField',
        'ui:hackEmptyValue': null,
        'ui:required': true,
      },
    },
  },
  modified_at: readonlyLocaleDate,
  'ui:order': [
    'id',
    'bezeichnung',
    'geom',
    'line_segment',
    'region',
    'rollen',
    'modified_at',
    'modified_by',
  ],
};

const netzentwicklungRegion = {
  id: hiddenReadOnly,
  rollen: {
    items: {
      'ui:title': '',
      person: {
        'ui:field': 'PersonField',
        'ui:hackEmptyValue': null,
      },
    },
  },
};

const anlagenverantwortlicheRegion = {
  ...anlagenverantwortliche,
  'ui:order': [
    'id',
    'region',
    'rolle',
    'unterrolle',
    'kommentar',
    'person',
    'modified_at',
    'modified_by',
  ],
};

const anlagenverantwortlicheSchweiz = {
  ...anlagenverantwortliche,
  'ui:order': ['id', 'person', 'rolle', 'modified_at', 'modified_by'],
};

const anlagenverantwortlicheNL = {
  ...anlagenverantwortliche,
  'ui:order': ['id', 'region', 'code', 'leiter', 'modified_at', 'modified_by'],
};

const direktverbindungen = {
  id: hiddenReadOnly,
  geom: hiddenReadOnly,
  description: {
    description_de: textArea,
    description_fr: textArea,
    description_it: textArea,
    description_en: textArea,
  },
  link: {
    url_de: { 'ui:options': { emptyValue: '' } },
    url_fr: { 'ui:options': { emptyValue: '' } },
    url_it: { 'ui:options': { emptyValue: '' } },
    url_en: { 'ui:options': { emptyValue: '' } },
  },
  modified_at: readonlyLocaleDate,
  'ui:order': [
    'id',
    'name',
    'nachtverbindung',
    'route',
    'geom',
    'description',
    'link',
    'modified_at',
    'modified_by',
  ],
  route: {
    'ui:field': 'RoutingField',
    vias: {
      items: { 'ui:field': 'DirektverbindungViaField' },
    },
  },
};

const energie = {
  life_cycle_manager: {
    'ui:field': 'PersonField',
    'ui:hackEmptyValue': null,
  },
  betrieb_instandhaltung: {
    'ui:field': 'PersonField',
    'ui:hackEmptyValue': null,
  },
  anlagebetreuer: { 'ui:field': 'PersonField', 'ui:hackEmptyValue': null },
  intervention: { 'ui:field': 'PersonField', 'ui:hackEmptyValue': null },
  schalt_erd_berechtigung: {
    'ui:field': 'PersonField',
    'ui:hackEmptyValue': null,
  },
  id: hiddenReadOnly,
  geom: hiddenReadOnly,
  bezeichnung: hiddenReadOnly,
};

const energiePersonSequence = [
  'anlagebetreuer',
  'betrieb_instandhaltung',
  'life_cycle_manager',
  'intervention_pikettnummer_tag',
  'intervention_pikettnummer_nacht',
  'intervention_pikettnummer_detail',
  'intervention_mail',
  'intervention_mail_detail',
  'intervention_bemerkungen',
  'intervention_persons',
  'intervention_energie_persons',
  'sicherheitsrelevant_link',
  'sicherheitsrelevant_bemerkungen',
  'sicherheitsrelevant_schalt_erd_berechtigt_persons',
  'sicherheitsrelevant_schalt_erd_berechtigt_energie_persons',
  'sicherheitsrelevant_sachverstaendige_persons',
  'sicherheitsrelevant_sachverstaendige_energie_persons',
  'sicherheitsrelevant_instruiert_persons',
  'sicherheitsrelevant_instruiert_energie_persons',
];

const energieCommonFields = {
  anlagebetreuer: {
    'ui:field': 'FormSectionTitledField',
    'ui:options': {
      'ui:customfield': 'PersonField',
      'ui:title': 'Asset Management',
    },
  },
  intervention_pikettnummer_tag: {
    'ui:hackEmptyValue': '',
    'ui:field': 'FormSectionTitledField',
    'ui:options': {
      'ui:title': 'Intervention',
    },
  },
  intervention_pikettnummer_nacht: { 'ui:hackEmptyValue': '' },
  intervention_pikettnummer_detail: { 'ui:hackEmptyValue': '' },
  intervention_mail: { 'ui:hackEmptyValue': '' },
  intervention_mail_detail: { 'ui:hackEmptyValue': '' },
  intervention_bemerkungen: { 'ui:hackEmptyValue': '' },
  intervention_persons: {
    'ui:options': {
      'ui:addButtonLabel': 'Person hinzufügen',
    },
    items: {
      'ui:title': '',
      person: {
        'ui:field': 'PersonField',
        'ui:hackEmptyValue': null,
        'ui:title': 'U-Nummer',
      },
      linienabschnitte: {
        'ui:options': {
          'ui:addButtonLabel': 'Linienabschnitt hinzufügen',
        },
        items: {
          'ui:title': '',
        },
      },
    },
  },
  intervention_energie_persons: {
    'ui:options': {
      'ui:addButtonLabel': 'Person hinzufügen',
    },
    items: {
      'ui:title': '',
      energie_person: {
        'ui:title': '',
        'ui:hackEmptyValue': '',
      },
      linienabschnitte: {
        'ui:options': {
          'ui:addButtonLabel': 'Linienabschnitt hinzufügen',
        },
        items: {
          'ui:title': '',
        },
      },
    },
  },
  sicherheitsrelevant_link: {
    'ui:field': 'FormSectionTitledField',
    'ui:hackEmptyValue': '',
    'ui:options': {
      'ui:title': 'Sicherheitsrelevante Personen',
    },
  },
  sicherheitsrelevant_bemerkungen: {
    'ui:hackEmptyValue': '',
  },
  sicherheitsrelevant_schalt_erd_berechtigt_persons: {
    'ui:options': {
      'ui:addButtonLabel': 'Person hinzufügen',
    },
    items: {
      'ui:field': 'PersonField',
      'ui:hackEmptyValue': null,
      'ui:title': 'U-Nummer',
    },
  },
  sicherheitsrelevant_schalt_erd_berechtigt_energie_persons: {
    'ui:options': {
      'ui:addButtonLabel': 'Person hinzufügen',
    },
    items: {
      'ui:title': '',
      'ui:hackEmptyValue': '',
      energie_person: {
        'ui:hackEmptyValue': '',
      },
    },
  },
  sicherheitsrelevant_instruiert_persons: {
    'ui:options': {
      'ui:addButtonLabel': 'Person hinzufügen',
    },
    items: {
      'ui:field': 'PersonField',
      'ui:hackEmptyValue': null,
      'ui:title': 'U-Nummer',
    },
  },
  sicherheitsrelevant_instruiert_energie_persons: {
    'ui:options': {
      'ui:addButtonLabel': 'Person hinzufügen',
    },
    items: {
      'ui:title': '',
      'ui:hackEmptyValue': '',
    },
  },
  sicherheitsrelevant_sachverstaendige_persons: {
    'ui:options': {
      'ui:addButtonLabel': 'Person hinzufügen',
    },
    items: {
      'ui:field': 'PersonField',
      'ui:hackEmptyValue': null,
      'ui:title': 'U-Nummer',
    },
  },
  sicherheitsrelevant_sachverstaendige_energie_persons: {
    'ui:options': {
      'ui:addButtonLabel': 'Person hinzufügen',
    },
    items: {
      'ui:title': '',
      'ui:hackEmptyValue': '',
    },
  },
};

const energieLeitungen = {
  ...energie,
  'ui:order': [
    'id',
    'geom',
    'bezeichnung',
    'trassennummer',
    'laenge',
    'dfa_id',
    'los_nr',
    ...energiePersonSequence,
    'modified_at',
    'modified_by',
  ],
  laenge: hiddenReadOnly,
  trassennummer: { 'ui:readonly': true },
  dfa_id: hiddenReadOnly,
  los_nr: { 'ui:readonly': true },
  ...energieCommonFields,
};

const energieAnlagen = {
  ...energie,
  'ui:order': [
    'id',
    'geom',
    'anlage_id',
    'kategorie',
    'unterkategorie',
    'adresse',
    'bezeichnung',
    ...energiePersonSequence,
    'modified_at',
    'modified_by',
  ],
  anlage_id: { 'ui:readonly': true },
  adresse: {
    'ui:widget': 'textarea',
    'ui:options': {
      emptyValue: '',
    },
  },
  ...energieCommonFields,
};

const energiePersonen = {
  id: hiddenReadOnly,
  created_at: hiddenReadOnly,
  created_by: hiddenReadOnly,
  'ui:order': [
    'id',
    'created_at',
    'created_by',
    'first_name',
    'last_name',
    'phone',
    'email',
    'modified_at',
    'modified_by',
  ],
  email: { 'ui:hackEmptyValue': '' },
};

const funkmesswagen = {
  id: hiddenReadOnly,
  geom: hiddenReadOnly,
  foto_1: {
    'ui:widget': 'ImageWidget',
    'ui:hackEmptyValue': null,
  },
  foto_2: {
    'ui:widget': 'ImageWidget',
    'ui:hackEmptyValue': null,
  },
  foto_3: {
    'ui:widget': 'ImageWidget',
    'ui:hackEmptyValue': null,
  },
  foto_4: {
    'ui:widget': 'ImageWidget',
    'ui:hackEmptyValue': null,
  },
  foto_5: {
    'ui:widget': 'ImageWidget',
    'ui:hackEmptyValue': null,
  },
  foto_6: {
    'ui:widget': 'ImageWidget',
    'ui:hackEmptyValue': null,
  },
  foto_7: {
    'ui:widget': 'ImageWidget',
    'ui:hackEmptyValue': null,
  },
  description: {
    'ui:widget': 'textarea',
    'ui:options': {
      emptyValue: '',
    },
  },
  modified_at: readonlyLocaleDate,
  'ui:order': [
    'id',
    'geom',
    'bezeichnung',
    'description',
    'category',
    'foto_1',
    'foto_2',
    'foto_3',
    'foto_4',
    'foto_5',
    'foto_6',
    'foto_7',
    'modified_at',
    'modified_by',
  ],
};

const railplusBetreiberinnen = {
  id: hiddenReadOnly,
  tu_number: hiddenReadOnly,
  logo: {
    'ui:widget': 'ImageWidget',
    'ui:hackEmptyValue': null,
    'ui:options': {
      'ui:message':
        'Das Logo im PDF-Export kann nur durch geOps ausgetauscht werden. Bitte informieren Sie uns unter jira@geops.ch, wenn Sie das Logo in Cartaro aktualisieren.',
    },
  },
  long_name: {
    'ui:hackEmptyValue': '',
  },
  highest_point: {
    'ui:hackEmptyValue': '',
  },
  lowest_point: {
    'ui:hackEmptyValue': '',
  },
  number_of_employees: {
    'ui:hackEmptyValue': '',
  },
  yearly_number_of_passengers: {
    'ui:hackEmptyValue': '',
  },
  route_length: {
    'ui:hackEmptyValue': '',
  },
  picture: {
    'ui:widget': 'ImageWidget',
    'ui:hackEmptyValue': null,
  },
  location_headquarter: {
    'ui:widget': 'textarea',
    'ui:options': {
      emptyValue: '',
    },
  },
  modified_at: readonlyLocaleDate,
  'ui:order': [
    'id',
    'name',
    'logo',
    'tu_number',
    'long_name',
    'location_headquarter',
    'cantons',
    'number_of_employees',
    'yearly_number_of_passengers',
    'route_length',
    'highest_point',
    'lowest_point',
    'picture',
    'modified_at',
    'modified_by',
  ],
};

const toExport = {
  immobilien,
  zweitausbildung_pois: zweitausbildungPois,
  zweitausbildung_stations: zweitausbildungStations,
  bauprojekte,
  mietvelo,
  anlagenverantwortliche,
  anlagenverantwortlicheSchweiz,
  anlagenverantwortlicheRegion,
  anlagenverantwortlicheNL,
  netzentwicklung,
  netzentwicklungRegion,
  direktverbindungen,
  energieLeitungen,
  energieAnlagen,
  energiePersonen,
  funkmesswagen,
  railplusBetreiberinnen,
};

export default toExport;
